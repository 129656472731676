
import { eoRendererMixin, metaDataMixin } from '~/mixins'

export default {
  mixins: [
    eoRendererMixin,
    metaDataMixin,
  ],
  async asyncData({ $api, $bugsnag, $config, $log, store, route }) {
    const slug = 'contact'
    const response = await $api.pages.getOneBySlug(slug, {
      domain: $config.preprEnvironmentDomain,
    })

    // check publication if exists and is complete
    if (!response?.data?.items?.[0]?.id) {
      $log.warn(`Couldn't create '${slug}' page.`)
      const { status, statusText, headers, config, data } = response
      $bugsnag.notify(new Error(`Couldn't create '${slug}' page.`), (e) => {
        e.severity = 'Error'
        e.context = `${slug} page`
        e.addMetadata('EO Api response', {
          status,
          statusText,
          headers,
          config,
          data,
        })
      })
      return {}
    }

    const page = response.data.items[0]

    store.dispatch('breadcrumbs/set', {
      path: route.fullPath,
      width: 6,
      items: [
        {
          to: '/',
          label: 'Over de EO',
        },
        {
          label: page.title,
        },
      ],
    })

    return page
  },
  data() {
    return {
      content: null,
      hero: null,
      intro: null,
      title: null,
    }
  },
}
